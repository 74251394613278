body {
	background-color: #f6f6f6 !important;
	padding: 1em !important;
	font-family: sans-serif !important;
	-webkit-font-smoothing: antialiased !important;
}

h1,
h2,
h3,
h4 {
	font-weight: 400 !important;
}

.breakword {
	overflow-wrap: break-word;
    word-break: break-word;
}

.search-task-status {
    line-height: 20px;
}

.no-border {
	border: 0 !important;
}

.table-row-header, .table-row-header > th, .table-row-header > td  {
    background-color: rgba(241,241,241,1.0) !important;
    text-shadow: none !important;
    background-image: none !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
}

.table-row-footer > th, .table-row-footer > td  {
	background-color: rgba(241,241,241,1.0) !important;
    text-shadow: none !important;
    background-image: none !important;
    font-size: 1rem !important;
    font-weight: 700 !important;

    padding-top: 5px !important;
	padding-bottom: 5px !important;
}

h1 > a {
    color: #000000;
}
